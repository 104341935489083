import React from "react"
import Layout from "../../layouts/default"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import Button from "../../components/button"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Divider from "../../components/divider"
import Heading from "../../components/heading"
import Image from "../../components/image"
import PageTitle from "../../components/page-title"
import Stack from "../../components/stack"
import Text from "../../components/text"
import TextLink from "../../components/textlink"

const ImageDownload = ({ title, description, image, href, attribution }) => {
  return (
    <Columns collapse={[true, false]} space={12}>
      <Column width="2/5">{image}</Column>
      <Column>
        <Stack space={6}>
          <Heading as="h4" level={5}>
            {title}
          </Heading>
          <Paragraph size={[3, 3, 4]}>{description}</Paragraph>
          {attribution && <Text>Bild: {attribution}</Text>}
          <Button href={href} secondary={true}>
            Download
          </Button>
        </Stack>
      </Column>
    </Columns>
  )
}

const PressePage = () => {
  const data = useStaticQuery(graphql`
    query PressePageQuery {
      aussen: file(
        relativePath: {
          eq: "presse/aussenansicht-hoelderlinturm-david-franck.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      turmUndBurse: file(
        relativePath: {
          eq: "presse/hoelderlinturm-und-burse-gudrun-de-maddalena.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      portraitHiemer: file(
        relativePath: {
          eq: "presse/friedrich-hoelderlin-portrait-franz-carl-hiemer-1792.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      portraitLohbauer: file(
        relativePath: {
          eq: "presse/friedrich-hoelderlin-portrait-rudolf-lohbauer-dla-marbach.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      eingang: file(
        relativePath: {
          eq: "presse/eingangssituation-im-erdgeschoss-david-franck.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      stift: file(
        relativePath: {
          eq: "presse/zeugnisse-aus-der-zeit-im-stift-david-franck.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      zimmer: file(
        relativePath: {
          eq: "presse/im-turm-beim-schreiner-zimmer-david-franck.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      sinnlich: file(
        relativePath: { eq: "presse/sinnliche-erfahrung-david-franck.jpg" }
      ) {
        ...smallImage
        publicURL
      }
      turmzimmer: file(
        relativePath: { eq: "presse/turmzimmer-david-franck.jpg" }
      ) {
        ...smallImage
        publicURL
      }
      sprachlabor: file(
        relativePath: { eq: "presse/sprachlabor-david-franck.jpg" }
      ) {
        ...smallImage
        publicURL
      }
      zwingel: file(
        relativePath: {
          eq: "presse/zwingel-weg-am-hoelderlinturm-barbara-klemm.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
      hoelderlinLiebt: file(
        relativePath: {
          eq: "presse/sonderausstellung-hoelderlin-liebt-illustration-jette-von-bodecker.jpg"
        }
      ) {
        ...smallImage
        publicURL
      }
    }
  `)

  const subject = `Pressebilder Hölderlinturm`
  const message = `Name:* %0D%0A Organisation:* %0D%0A Kontakt:* %0D%0A %0D%0A %0D%0A %0D%0A Welche Bilder verwenden Sie?* %0D%0A Wofür verwenden Sie die Bilder?`

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Presse"
        description="Für Anfragen zu Pressebeiträgen und Interviews können Sie gerne Kontakt zur Presse- und Öffentlichkeitsarbeit der Universitätsstadt Tübingen aufnehmen."
      />

      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Presse",
                link: "/presse",
              },
            ]}
          />
          <PageTitle>Presse</PageTitle>
          <Paragraph dropcap={true}>
            Für Anfragen zu Pressebeiträgen und Interviews können Sie gerne
            Kontakt zur Presse- und Öffentlichkeitsarbeit der Universitätsstadt
            Tübingen aufnehmen.
          </Paragraph>
          <Paragraph>
            Universitätsstadt Tübingen <br />
            Presse- und Öffentlichkeitsarbeit <br />
            Am Markt 1 <br />
            72070 Tübingen
          </Paragraph>
          <Paragraph>
            07071 204-1500 <br />
            <TextLink href="mailto:presse@tuebingen.de">
              presse@tuebingen.de
            </TextLink>
          </Paragraph>
        </Stack>

        <Divider size={4} />

        <Stack>
          <Heading as="h2" level={2}>
            Bildmaterial
          </Heading>
          <Paragraph>
            Hier finden Sie Bildmaterial zu unserem Museum. Für die Verwendung
            der Bilder benötigen Sie eine Publikationsgenehmigung. Bitte
            beachten Sie dafür die Nutzungsbedingungen für den Bilderdownload
            und{" "}
            <TextLink
              href={`mailto:hoelderlinturm@tuebingen.de?subject=${subject}&body=${message}`}
            >
              teilen Sie uns per Email den Verwendungszweck mit
            </TextLink>
            . Vielen Dank!
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h3" level={4}>
            Der Hölderlinturm
          </Heading>
          <Stack>
            <ImageDownload
              title="Hölderlinturm und Burse"
              description="Im Vordergrund: Der Hölderlinturm. Im Hintergrund: Die Burse, in der einst das Universitätsklinikum untergebracht war, in dem Hölderlin behandelt wurde."
              attribution="Gudrun de Maddalena"
              href={data.turmUndBurse.publicURL}
              image={
                <Image
                  image={data.turmUndBurse.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
            <ImageDownload
              title="Außenansicht Hölderlinturm"
              description="Mit seiner idyllischen Lage am Neckar ist der Hölderlinturm ein Wahrzeichen Tübingens. Aber auch darüber hinaus ist er ein weltweit bedeutender literarischer Erinnerungsort."
              attribution="David Franck"
              href={data.aussen.publicURL}
              image={
                <Image
                  image={data.aussen.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
          </Stack>
        </Stack>

        <Stack>
          <Heading as="h3" level={4}>
            Friedrich Hölderlin
          </Heading>
          <Stack>
            <ImageDownload
              title="Porträt Friedrich Hölderlin (1792)"
              description="Das heute bekannteste Porträt des Dichters zeigt Hölderlin im Alter von 22 Jahren. Gezeichnet hat es der Maler Franz Carl Hiemer."
              attribution="DLA Marbach"
              href={data.portraitHiemer.publicURL}
              image={
                <Image
                  image={data.portraitHiemer.childImageSharp.gatsbyImageData}
                  alt="Porträt Hiemer"
                />
              }
            />
            <ImageDownload
              title="Porträt Friedrich Hölderlin (1823)"
              description="Im Juli 1823 erhielt Hölderlin Besuch von Eduard Mörike und dessen Freunden Rudolph Lohbauer und Johann Georg Schreiner. Dabei entstand – »in Eile gezeichnet« – eines der wenigen Porträts aus der Zeit im Turm. Mörike fand, es sei »ziemlich charakteristisch«."
              attribution="DLA Marbach"
              href={data.portraitLohbauer.publicURL}
              image={
                <Image
                  image={data.portraitLohbauer.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
          </Stack>
        </Stack>

        <Stack>
          <Heading as="h3" level={4}>
            Die Dauerausstellung
          </Heading>
          <Stack>
            <ImageDownload
              title="Eingangssituation im Erdgeschoss"
              description="Schon die Licht-Gestaltung im Eingangsbereich greift Hölderlins Spiel mit den Silben und Betonungen auf."
              attribution="David Franck"
              href={data.eingang.publicURL}
              image={
                <Image
                  image={data.eingang.childImageSharp.gatsbyImageData}
                  alt="Porträt Hiemer"
                />
              }
            />
            <ImageDownload
              title="Im Turm beim Schreiner Zimmer"
              description="In der einstigen Schreiner-Werkstatt entstanden Hölderlins Verse ›Die Linien des Lebens‹, die dort in zahlreichen Übersetzungen zu lesen sind."
              attribution="David Franck"
              href={data.zimmer.publicURL}
              image={
                <Image
                  image={data.zimmer.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
            <ImageDownload
              title="Zeugnisse aus der Zeit im Stift"
              description="In der Ausstellung gibt es jede Menge zu entdecken: Frühe Gedichte und Zeugnisse geben Einblick in Hölderlins Studienzeit am Tübinger Stift und die dort geknüpften Freundschaften."
              attribution="David Franck"
              href={data.stift.publicURL}
              image={
                <Image
                  image={data.stift.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
            <ImageDownload
              title="Sinnliche Erfahrung"
              description="Hölderlins Versmaße werden in der Ausstellung hörbar und spürbar: Ein Holzbrett vibriert bei jeder betonten Silbe."
              attribution="David Franck"
              href={data.sinnlich.publicURL}
              image={
                <Image
                  image={data.sinnlich.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
            <ImageDownload
              title="Turmzimmer"
              description="Aus den fünf Fenstern seines Turmzimmers konnte Hölderlins Blick weit schweifen – vom Neckar bis zu den Bergen der schwäbische Alb. Das einzig erhaltene Möbelstück: ein kleiner Tisch, auf den der Dichter »mit der Hand geschlagen, wenn er Streit gehabt – mit seinen Gedanken«. (Lotte Zimmer)"
              attribution="David Franck"
              href={data.turmzimmer.publicURL}
              image={
                <Image
                  image={data.turmzimmer.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
            <ImageDownload
              title="Sprachlabor"
              description="Im Sprachlabor wird es interaktiv: Darin kann jeder selbst mit Hölderlins Worten, Silben, Rhythmen und Versmaßen experimentieren."
              attribution="David Franck"
              href={data.sprachlabor.publicURL}
              image={
                <Image
                  image={data.sprachlabor.childImageSharp.gatsbyImageData}
                  alt="Außenansicht Hölderlinturm"
                />
              }
            />
          </Stack>
        </Stack>

        <Stack>
          <Heading as="h3" level={4}>
            Weitere Bilder
          </Heading>
          <Stack>
            <ImageDownload
              title="Wanderausstellung ›Hölderlins Orte - Fotografien von Barbara Klemm‹"
              description="In der Ausstellung treffen Friedrich Hölderlins Verse und Landschaftsbeschreibungen auf Bilder der Fotografin Barbara Klemm. Hier: Der sogenannte Zwingel, ein schmaler Weg vor dem Hölderlinturm, in dem der Dichter oft stundenlang auf und ab ging."
              attribution="Barbara Klemm"
              href={data.zwingel.publicURL}
              image={
                <Image
                  image={data.zwingel.childImageSharp.gatsbyImageData}
                  alt="Schwarzweiß-Fotografie des Zwingels, ein schmaler Weg am Hölderlinturm"
                />
              }
            />
            <ImageDownload
              title="Sonderaussstellung ›Hölderlin liebt...‹"
              description="In der inklusiven Sonderausstellung ›Hölderlin liebt...‹ (9. Mai 2021 bis 14. Februar 2022) werden Friedrich Hölderlin und seine Gedichte in Einfacher Sprache und Gebärdensprache zugänglich gemacht."
              attribution="Jette von Bodecker"
              href={data.hoelderlinLiebt.publicURL}
              image={
                <Image
                  image={data.hoelderlinLiebt.childImageSharp.gatsbyImageData}
                  alt="Eine Illustration zeigt Hölderlin am Schreibtisch"
                />
              }
            />
          </Stack>
        </Stack>

        <Constrain>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              Nutzungsbedingungen für den Bilderdownload
            </Heading>
            <Paragraph>
              Die Universitätsstadt Tübingen räumt den Nutzerinnen und Nutzer an
              den überlassenen Bilddaten ein einfaches Nutzungsrecht
              honorarfrei* ein, sofern deren Verwendung den Zwecken der
              touristischen Werbung und Public-Relations-Arbeit dient.
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Zur honorarfreien Verwendung zählen insbesondere:
              </Heading>
              <BulletList>
                <Bullet>
                  Die Gestaltung touristischer Angebote sowie sonstige werbliche
                  Veröffentlichungen durch in- und ausländische
                  Reiseveranstalter, Reisebüros, Fluggesellschaften, Bahn- und
                  Busunternehmen, Hotels, Messe-, Kongress- und
                  Tagungsorganisationen, Incoming-Büros und ähnliche Unternehmen
                  (beispielsweise auch Reiseberater in Industriefirmen).
                </Bullet>
                <Bullet>
                  Die Gestaltung publizistischer Beiträge über Tübingen in in-
                  und ausländischen Zeitungen, Zeitschriften, Illustrierten und
                  Broschüren sowie Reiseführer (Grundsatz: touristisch
                  informativer und im weitesten Sinne werblicher Charakter des
                  bildbegleitenden Textes).
                </Bullet>
                <Bullet>
                  Die werbliche Unterstützung von Tagungen, Kongressen, Messen
                  und Ausstellungen in Tübingen.
                </Bullet>
              </BulletList>
            </Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Ausgeschlossen von der honorarfreien Nutzung sind
              </Heading>
              <BulletList>
                <Bullet>
                  Arten der Verwendung, bei denen das Bild primär die Basis für
                  eine wirtschaftliche Gewinnerzielung bietet. Dies ist
                  insbesondere der Fall bei Anzeigen, Postkarten, Kalendern,
                  Bildbänden, Postern/Plakaten (ausgenommen touristische
                  Werbeplakate) und ähnlichen Objekten.
                </Bullet>
                <Bullet>
                  Die gestalterische Unterstützung von Warenpräsentationen oder
                  Dienstleistungsangeboten durch Wirtschaftsunternehmen
                  außerhalb der Touristikbranche, soweit diese das Bildmaterial
                  ausschließlich zur werblichen Unterstützung ihrer Absatz- und
                  Imageinteressen nutzen wollen (z. B. als Hintergrundmotiv bei
                  einer Schaufensterdekoration für Bekleidung oder zur
                  Imagedarstellung in Broschüren für Immobilienangebote).
                </Bullet>
              </BulletList>
            </Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Verbot von Vervielfältigungen, digitaler Speicherung und
                Weitergabe
              </Heading>
              <BulletList>
                <Bullet>
                  Die Herstellung von Vervielfältigungen des honorarfrei
                  überlassenen Bildmaterials für eigene Archivzwecke ist
                  untersagt.
                </Bullet>
                <Bullet>
                  Die Weitergabe des Bildmaterials an Dritte darf nur zum Zwecke
                  der Reproduktion erfolgen.
                </Bullet>
              </BulletList>
            </Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Verbot von Verfremdung und Verfälschung
              </Heading>
              <Text>
                Eine Entstellung oder Verfälschung des urheberrechtlich
                geschützten Werkes durch Abzeichnen, Nachfotografieren,
                Fotocomposing und sonstige Veränderungen auf fotomechanischem
                oder digitalem Wege ist nicht gestattet. Ausnahmen bedürfen der
                Zustimmung der Universitätsstadt Tübingen und im Einzelfall auch
                des jeweiligen Bildautors.
              </Text>
            </Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Haftung für das überlassene Bildmaterial
              </Heading>
              <Text>
                Die Universitätsstadt Tübingen haftet nicht für
                Schadensersatzforderungen, die sich eventuell aus der Verwendung
                des überlassenen Bildmaterials ergeben sollten. Der Nutzer trägt
                in jedem Fall die völlige Verantwortung selber, auch die aus dem
                Recht am eigenen Bild. Die Universitätsstadt Tübingen haftet
                auch nicht für Ansprüche, die sich aus der Verletzung des
                Copyrights ergeben sollten.
              </Text>
            </Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Namensnennung des Bildautors/Belegexemplare
              </Heading>
              <Text>
                Das Copyright ist jeweils sowohl im Alt-Text als auch in in den
                IPTC-Daten im Bild selbst enthalten. Es muss bei jeder
                Veröffentlichung entweder direkt am Bild oder im
                Bildquellennachweis angegeben werden. Von jeder Veröffentlichung
                ist der Universitätsstadt Tübingen umgehend und unaufgefordert
                ein vollständiges Belegexemplar zu übersenden. Bei Nutzung der
                Bilder im Internet ist der Universitätsstadt unverzüglich die
                vollständige Adresse der entsprechenden Internet-Seite
                mitzuteilen.
              </Text>
            </Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Einhaltung des Pressekodex
              </Heading>
              <Text>
                Der Nutzer ist zur Beachtung der publizistischen Grundsätze des
                Deutschen Presserates (Pressekodex) verpflichtet. Er trägt die
                Verantwortung für die Betextung. Für eine Verletzung des
                allgemeinen Persönlichkeitsrechts oder des Urheberrechts durch
                eine abredewidrige, sittenwidrige und sinnentstellende
                Verwendung in Bild und Text übernimmt die Universitätsstadt
                Tübingen keine Haftung. Gleiches gilt für eine herabwürdigende
                Darstellung von abgebildeten Personen auf den überlassenen
                Bildern. Bei Verletzung solcher Rechte ist allein der Nutzer
                etwaigen Dritten gegenüber schadenersatzpflichtig.
              </Text>
            </Stack>
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default PressePage
